import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import { pin } from "../icons"

import Layout from "../components/layout"
import Map from "../components/map"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const POIs = [
    {
      title: "Peluquería Premium",
      icon: pin,
      lat: 43.48865132242907,
      lng: -6.112769996182918,
      info: "https://goo.gl/maps/RPSUkH8CquWnMcow9",
      link: "https://goo.gl/maps/RPSUkH8CquWnMcow9",
      img:
        "/img/peluquerias/premium.jpg",
    },
    {
      title: "Hermanos González",
      icon: pin,
      lat: 43.48789068489426,
      lng: -6.111595522243548,
      info: "https://hermanos-gonzalez-peluqueros.negocio.site/",
      link: "https://goo.gl/maps/U5u3TMgUDBnTNaGR7",
      img:
        "/img/peluquerias/hermanos-gonzalez.jpg",
    },
    {
      title: "La pelu de Bea",
      icon: pin,
      lat: 43.488954500519746, 
      lng: -6.112592182449023,
      info: "https://goo.gl/maps/9ueRvoekSoSXc7P39",
      link: "https://goo.gl/maps/9ueRvoekSoSXc7P39",
      img:
        "/img/peluquerias/la-pelu-de-bea.jpg",
    },
  ]

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Peluquerías</h2>
        <Separator />
        <p>
          Aquí os dejamos una lista de peluquerías muy cerca del lugar de la celebración.
        </p>
      </Section>

      <Map
        center={{
          lat: 43.4647896,
          lng: -5.7844736,
        }}
        zoom={10}
        POIs={POIs}
        selected={1}
      />
    </Layout>
  )
}
